export const EthereumNameService = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 207.921 231.7"
    >
      <g transform="translate(-26.782 -209.6)">
        <path
          fill="currentColor"
          d="M53.5,303.4c2.3,4.9,8,14.6,8,14.6L127,209.7,63,254.3a28.2,28.2,0,0,0-9.1,10A46.469,46.469,0,0,0,53.5,303.4Z"
        />
        <path
          fill="currentColor"
          d="M27.5,339a73.057,73.057,0,0,0,28.6,53l70.7,49.3S82.6,377.5,45.2,314.1a63.856,63.856,0,0,1-7.5-21.6,36.239,36.239,0,0,1,0-10.4c-1,1.8-2.9,5.5-2.9,5.5a81.14,81.14,0,0,0-7.6,24.5A161.114,161.114,0,0,0,27.5,339Z"
        />
        <path
          fill="currentColor"
          d="M207.9,347.6c-2.3-4.9-8-14.6-8-14.6L134.4,441.3l63.9-44.6a28.2,28.2,0,0,0,9.1-10A46.117,46.117,0,0,0,207.9,347.6Z"
        />
        <path
          fill="currentColor"
          d="M233.9,311.9a73.057,73.057,0,0,0-28.6-53l-70.7-49.3s44.2,63.8,81.6,127.2a63.855,63.855,0,0,1,7.5,21.6,36.239,36.239,0,0,1,0,10.4c1-1.8,2.9-5.5,2.9-5.5a81.14,81.14,0,0,0,7.6-24.5A161.228,161.228,0,0,0,233.9,311.9Z"
        />
        <path
          fill="currentColor"
          d="M53.9,264.3a28.2,28.2,0,0,1,9.1-10l63.9-44.6L61.4,318s-5.7-9.7-8-14.6A46.471,46.471,0,0,1,53.9,264.3ZM27.5,339a73.057,73.057,0,0,0,28.6,53l70.7,49.3S82.6,377.5,45.2,314.1a63.856,63.856,0,0,1-7.5-21.6,36.239,36.239,0,0,1,0-10.4c-1,1.8-2.9,5.5-2.9,5.5a81.14,81.14,0,0,0-7.6,24.5A161.114,161.114,0,0,0,27.5,339Zm180.3,8.6c-2.3-4.9-8-14.6-8-14.6L134.4,441.3l63.9-44.6a28.2,28.2,0,0,0,9.1-10,46.106,46.106,0,0,0,.4-39.1Zm26-35.6a73.057,73.057,0,0,0-28.6-53l-70.6-49.3s44.2,63.8,81.6,127.2a63.855,63.855,0,0,1,7.5,21.6,36.239,36.239,0,0,1,0,10.4c1-1.8,2.9-5.5,2.9-5.5a81.14,81.14,0,0,0,7.6-24.5,152.188,152.188,0,0,0-.2-27Z"
        />
      </g>
    </svg>
  );
};
