import { zodResolver } from '@hookform/resolvers/zod';
import { confirmResetPassword } from 'aws-amplify/auth';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Input, toast } from '@polyphonic/ui';

type NewPasswordInput = {
  new_password: string;
  confirm_password: string;
};

const NewPasswordSchema = z
  .object({
    new_password: z
      .string()
      .min(1, { message: 'Required' })
      .min(8, 'Password is too short')
      .max(50, 'Password is too long'),
    confirm_password: z
      .string()
      .min(1, { message: 'Required' })
      .min(8, 'Password is too short')
      .max(50, 'Password is too long'),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: 'Passwords must match',
    path: ['confirm_password'],
  });

export const NewPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<NewPasswordInput>({
    resolver: zodResolver(NewPasswordSchema),
    mode: 'onChange',
  });

  const {
    query: { forgot },
  } = useRouter();

  const {
    query: { code },
  } = useRouter();

  const handleNewPassword: SubmitHandler<NewPasswordInput> = async ({
    new_password,
  }) => {
    setIsSubmitting(true);
    confirmResetPassword({
      username: forgot as string,
      newPassword: new_password,
      confirmationCode: code as string,
    })
      .then(() => {
        setIsSubmitting(false);
      })
      .catch((err) => {
        toast.error(`${err}: Please request a new password reset.`);
        setIsSubmitting(false);
      });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className="flex justify-end flex-col w-full">
      <p className="text-center mt-3">Choose new password</p>
      <form onSubmit={handleSubmit(handleNewPassword)} className="mt-3 w-full">
        <Input
          id="new_password"
          label="New Password"
          errorMessage={errors.new_password?.message}
          className="mb-3"
          {...register('new_password')}
          type="password"
        />
        <Input
          id="confirm_password"
          label="Confirm Password"
          errorMessage={errors.confirm_password?.message}
          className="mb-3"
          {...register('confirm_password')}
          type="password"
        />
        <Button
          className="w-full text-center"
          type="submit"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
