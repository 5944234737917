import { fetchAuthSession } from 'aws-amplify/auth';
import { getUrl, remove } from 'aws-amplify/storage';
import { customAlphabet } from 'nanoid';

import { MediaKind, S3AccessLevel } from './constants';

export interface AuthErrorMessage {
  message: string;
  log?: string;
}

const nanoid = customAlphabet('1234567890', 6);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const title = require('title');

/**
 * Description: Return client-side dev environment
 *
 * @returns boolean
 */
export const isDev = () =>
  process.env.NODE_ENV === 'development' ? true : false;

/**
 * Description: Return client-side dev environment
 *
 * @returns boolean
 */
export const notProd = () => {
  return (
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  );
};

/**
 * Description: Converts strings to enums.
 *
 * @param  {string} label
 * @returns string
 */
export const createEnums = (label: string): string =>
  label ? label.replace(/ /g, '_').toUpperCase() : '';

/**
 * Description: Formats out _ from enum labels.
 *
 * @param  {string} label
 * @returns string
 */
export const formatEnums = (label: string): string =>
  label ? title(label.replace(/_/g, ' ')) : '';

/**
 * Description: Formats Sanity block context to plaintext
 *
 * @param  {object} blocks
 * @returns string
 */
export const blocksToText = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blocks: any,
  opts = {},
) => {
  const options = Object.assign({}, { nonTextBehavior: 'remove' }, opts);
  return blocks
    ?.map((block: { _type: string; children: { text: string }[] }) => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove'
          ? ''
          : `[${block._type} block]`;
      }
      return block.children
        .map((child: { text: string }) => child.text)
        .join('');
    })
    .join('\n\n');
};

/**
 * Description: Formats ISO date to DD MONTH YYYY
 *
 * @param  {string} isodate
 * @returns string
 */
export const prettyIsoDate = (isodate: string) => {
  const iso = new Date(isodate);
  const day = iso.getDate();
  const month = iso.getMonth();
  const year = iso.getFullYear();
  const date = new Date(Date.UTC(year, month, day));
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return date.toLocaleDateString(undefined, options);
};

/**
 * Description: Formats date to DD MONTH YYYY
 *
 * @param  {string} date
 * @returns string
 */
export const prettyDate = (date: string) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return date.toLocaleDateString(undefined, options);
};

/**
 * Description: Strips time from ISO date
 *
 * @param  {Date} date
 * @returns string
 */
export function stripTime(date: Date) {
  // ✅ Format a date to YYYY-MM-DD (or any other format)
  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}

/**
 * Description: Create S3 File Name
 *
 * @param  {string} kind
 * @returns string
 */
export const objectName = (kind: MediaKind) => {
  return `${kind.toLowerCase()}/${nanoid()}`;
};

/**
 * Description: S3 key for storing in the DB (including access level and identity id)
 *
 * @param  {string} filename
 * @param  {S3AccessLevel} access_level
 * @param  {MediaKind} kind
 * @param  {string} identifier
 * @returns string
 */
export const putS3Key = (
  filename: string,
  access_level: S3AccessLevel,
  kind: MediaKind,
  identifier?: string, // Preferred username or identity ID depending on access level
) => {
  if (access_level === S3AccessLevel.GUEST) {
    return `public/${identifier}/${kind.toLowerCase()}/${filename}`;
  } else {
    return `${access_level.toLowerCase()}/${identifier}/${kind.toLowerCase()}/${filename}`;
  }
};

/**
 * Description: Key for retrieving from S3 (without access level or identity id)
 *
 * @param  {string} key
 * @param  {S3AccessLevel} access_level
 * @param  {string} identifier
 * @returns string
 */
export const getS3Key = (
  key: string,
  access_level: S3AccessLevel,
  identifier?: string, // Preferred username or identity ID depending on access level
) => {
  if (access_level === S3AccessLevel.GUEST) {
    return key.replace(`public/${identifier}/`, '');
  } else {
    return key.replace(`${access_level.toLowerCase()}/${identifier}/`, '');
  }
};

/**
 * Description: Return public object URL from S3
 *
 * @param  {string} key
 * @returns string
 */
export const objectURL = (key: string) => {
  return `https://${process.env.NEXT_PUBLIC_AWS_S3_BUCKET}.s3.amazonaws.com/${key}`;
};

/**
 * Description: Delete object from S3 Bucket
 *
 * @param  {string} key
 * @returns string
 */
export const deleteObject = async (key: string) => {
  const level = key.split('/')[0];
  const credentials = await fetchAuthSession();
  const identityId = credentials.identityId;
  const path = key.replace(`${level}/${identityId}/`, '');
  return await remove({
    key: path,
    options: {
      accessLevel:
        level === 'private'
          ? 'private'
          : level === 'protected'
            ? 'protected'
            : 'guest',
    },
  });
};

/**
 * Description: Retrieve private item from S3
 *
 * @param  {string} key
 * @returns string
 */
export const vault = async (key: string) => {
  return await getUrl({
    key,
    options: {
      accessLevel: 'protected',
    },
  });
};

/**
 * Description: Download blob
 *
 * @param  {blob} blob
 * @param  {string} filename
 * @returns a
 */
export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

/**
 * Description: Get song duration from file
 *
 * @param  {blob} file
 * @returns number
 */
export const getDuration = async (file: Blob) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  const durationPromise = new Promise<number>((resolve, reject) => {
    reader.onloadend = (e) => {
      const ctx = new AudioContext();
      const audioArrayBuffer = e?.target?.result;
      ctx.decodeAudioData(audioArrayBuffer as ArrayBuffer, (data) => {
        resolve(data.duration);
      });
    };
    reader.onerror = reject;
  });
  const duration = await durationPromise;
  return duration;
};

/**
 * Description: Remove file extension
 *
 * @param  {string} filename
 * @returns string
 */
export function removeExtension(filename: string) {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
}
