export const Rocket = () => {
  return (
    <svg viewBox="0 0 1485 1034" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.85">
        <path
          d="M1235.04 171.063L1297.04 214.063C1297.04 214.063 1196.54 247.07 1117.04 266.07C1037.54 285.07 1031.28 273.975 979.042 290.064C926.806 306.153 1016.26 377.35 979.042 400.812C941.824 424.274 830.17 383.912 830.17 383.912C830.17 383.912 869.04 490.806 796.37 530.943C723.7 571.08 539.489 544.463 539.489 544.463C539.489 544.463 516.673 594.74 470.198 615.442C423.723 636.144 353.588 627.272 353.588 627.272C353.588 627.272 370.127 651.74 353.588 698.309C346.338 718.723 285.43 754.709 294.439 775.993C323.592 844.861 209.406 844.322 304.046 911.077C398.686 977.832 673 1043.01 673 1043.01L120.368 1098.79C120.368 1098.79 -116.655 988.085 -131.443 885.844C-146.231 783.603 -15.825 825.021 79.045 729.076C92.706 715.26 -5.66496 655.559 36.583 598.806C78.831 542.053 152.418 572.413 248.039 502.064C359.746 419.881 418.539 390.607 508.039 361.064C597.539 331.521 606.039 383.906 606.039 383.906L722.039 316.058C722.039 316.058 693.739 227.458 770.813 190.604C795.465 178.817 895.878 212.872 927.043 204.063C1055.3 167.818 1235.04 171.063 1235.04 171.063Z"
          fill="#B2B2D2"
        />
        <path
          d="M314.368 638.717C314.368 638.717 259.173 541.017 283.597 512.56C308.021 484.103 412.061 524.87 412.061 524.87C412.061 524.87 429.751 535.828 438.982 507.17C448.213 478.512 423.213 454.479 448.982 410.248C474.751 366.017 586.682 364.097 586.682 364.097C586.682 364.097 593.723 335.063 635.069 329.097C676.415 323.131 708.315 344.997 752.069 340.243C795.823 335.489 799.323 342.133 810.069 310.097C820.815 278.061 774.319 232.847 795.069 212.097C815.819 191.347 879.642 232.435 925.142 227.935C970.642 223.435 893.353 211.87 977.07 194.096C1002.03 188.796 1048.08 180.546 1091.25 186.321C1111.49 189.028 1126.81 187.942 1146.78 186.321C1224.44 180.023 1283.78 157.2 1283.78 157.2L1303.85 201.946C1303.85 201.946 1218.84 231.797 1143.59 235.946C1111.67 237.705 1070.07 205.477 1056.07 212.108C1009.07 234.358 1081.07 260.358 1056.07 271.108C1031.07 281.858 987.066 240.608 956.066 255.108C925.066 269.608 957.316 325.108 932.066 329.108C906.816 333.108 886.815 259.358 855.066 271.108C823.317 282.858 835.066 349.859 805.066 376.108C775.066 402.357 760.678 368.24 754.735 387.739C748.792 407.238 813.582 423.639 781.293 454.102C763.566 470.825 669.682 432.791 626.98 439.864C592.254 445.616 605.908 497.949 605.908 497.949C605.908 497.949 626.486 528.334 579.755 547.949C533.024 567.564 472.06 538.521 418.982 576.406C365.904 614.291 399.944 672.371 367.443 699.486C334.942 726.601 288.981 684.867 288.981 684.867C288.981 684.867 339.761 737.218 302.261 787.6C288.161 806.545 223.776 777.714 195.661 787.6C149.27 803.912 253.456 841.38 255.136 872.1C256.189 891.349 121.673 889.43 121.561 913.7C121.39 950.767 438.983 1102.81 438.983 1102.81L-7.29521 1072.4C-7.29521 1072.4 -26.9822 917.173 -17.1742 862.557C-7.36621 807.941 98.2108 807.942 98.2108 807.942C98.2108 807.942 117.636 721.405 152.829 699.483C188.022 677.561 238.983 720.253 238.983 720.253C238.983 720.253 154.75 641.406 173.6 621.021C192.45 600.636 314.368 638.717 314.368 638.717Z"
          fill="#F5F5F9"
        />
        <path
          d="M258.086 493.627L309.16 463.478V592.018L336.466 575.913V621.667L309.16 637.983V690.831L259.86 719.205V667.422L164.805 719.205V678.062L258.086 493.627Z"
          fill="#DB7070"
        />
        <path
          d="M207.367 463.478L259.151 492.917L164.451 679.835L114.086 651.461L207.367 463.478Z"
          fill="#D04040"
        />
        <path
          d="M113.856 650.427L164.871 679.003V718.878L113.856 690.142V650.427Z"
          fill="#AB3535"
        />
        <path
          d="M238.931 607.604L259.545 565.133V619.033L247.5 612.451L238.931 607.604Z"
          fill="#AB3535"
        />
        <path
          d="M259.476 618.422L219.192 644.355L239.306 607.111L251.135 613.544L259.476 618.422Z"
          fill="#D04040"
        />
        <path
          d="M211.892 692.722L260.638 666.143L260.299 718.934L240.77 708.888L211.892 692.722Z"
          fill="#AB3535"
        />
        <path
          d="M308.29 559.997L324.916 570.218L336.47 576.523L308.29 593.336V559.997Z"
          fill="#D04040"
        />
        <path
          d="M207.656 463.828L258.821 435.141L309.24 463.828L258.821 493.628L207.656 463.828Z"
          fill="#AB3535"
        />
        <path
          d="M334.297 466.983C334.625 436.683 339.856 415.62 346.297 399.047C351.912 384.753 358.712 370.954 366.626 357.794C366.626 357.794 393.493 316.294 424.439 298.561C455.385 280.828 480.305 288.094 502.657 295.961C525.009 303.828 549.287 329.771 549.287 329.771L407.769 563.156C407.769 563.156 378.969 557.375 360.596 533.332C342.223 509.289 333.969 497.283 334.297 466.983Z"
          fill="url(#paint0_linear_101_2)"
        />
        <path
          d="M511.341 318.431C561.908 318.431 577.27 362.726 562.061 425.545C546.852 488.364 498.839 546.758 453.169 562.453C407.499 578.148 380.459 559.334 380.459 488.324C380.459 417.314 460.774 318.431 511.341 318.431Z"
          fill="#DB7070"
        />
        <path
          d="M447.3 508.855C464.857 515.948 490.917 492.424 505.507 456.313C520.097 420.201 517.692 385.176 500.135 378.083C482.578 370.99 456.518 394.513 441.928 430.625C427.338 466.737 429.743 501.761 447.3 508.855Z"
          fill="#AB3535"
        />
        <path
          d="M465.21 392.538C465.21 392.538 463.614 416.475 455.793 433.247C447.972 450.019 433.543 461.212 433.543 461.212C433.543 461.212 436.153 439.894 443.975 423.12C451.797 406.346 465.21 392.538 465.21 392.538Z"
          fill="#6B6D9A"
        />
        <path
          d="M718.615 164.009L769.689 133.86V262.401L796.995 246.295V292.049L769.689 308.365V361.213L720.389 389.587V337.804L625.334 389.587V348.444L718.615 164.009Z"
          fill="#DB7070"
        />
        <path
          d="M667.896 133.86L719.68 163.299L624.98 350.217L574.615 321.843L667.896 133.86Z"
          fill="#D04040"
        />
        <path
          d="M574.385 320.81L625.4 349.386V389.261L574.385 360.525V320.81Z"
          fill="#AB3535"
        />
        <path
          d="M699.461 277.987L720.075 235.516V289.416L708.03 282.834L699.461 277.987Z"
          fill="#AB3535"
        />
        <path
          d="M720.004 288.805L679.721 314.738L699.834 277.494L711.663 283.926L720.004 288.805Z"
          fill="#D04040"
        />
        <path
          d="M672.421 363.104L721.167 336.525L720.828 389.316L701.299 379.27L672.421 363.104Z"
          fill="#AB3535"
        />
        <path
          d="M768.819 230.38L785.445 240.601L796.999 246.906L768.819 263.719V230.38Z"
          fill="#D04040"
        />
        <path
          d="M668.185 134.21L719.35 105.523L769.769 134.21L719.35 164.01L668.185 134.21Z"
          fill="#AB3535"
        />
        <path
          d="M1380.36 89.8689C1412.02 87.1648 1409.33 95.1934 1409.33 95.1934C1409.33 95.1934 1413.92 103.626 1383.83 113.728C1353.75 123.829 1293.95 145.194 1293.95 145.194C1293.95 145.194 1310.35 130.155 1307.89 118.815C1305.43 107.476 1284.12 99.8474 1284.12 99.8474C1284.12 99.8474 1348.7 92.5694 1380.36 89.8689Z"
          fill="url(#paint1_linear_101_2)"
        />
        <path
          d="M1409.52 94.6267C1409.52 94.6267 1413.09 98.5497 1398.09 108.989C1393.04 112.507 1379.34 118.32 1363.17 124.578C1332.05 136.616 1292.01 150.097 1292.01 150.097L1313.53 131.834L1358.2 119.551C1358.2 119.551 1385.09 111.926 1396 106.967C1400.71 104.412 1405.63 101.84 1407.99 99.0087C1409.01 97.7761 1409.55 96.2223 1409.52 94.6267Z"
          fill="#E86C6C"
        />
        <path
          d="M1408.72 92.6752C1409.21 93.5173 1409.5 94.4649 1409.55 95.4395C1409.61 96.4141 1409.43 97.3876 1409.03 98.2794C1408.03 100.908 1404.73 104.288 1397.92 109.159C1383.2 119.689 1290.99 150.838 1290.99 150.838L1304.64 143.35C1304.64 143.35 1338.94 131.999 1361.43 123.567C1373.55 119.023 1387.16 114.153 1397.13 108.398C1401.3 105.995 1406.57 102.221 1408.51 98.7217C1409.01 97.8017 1409.3 96.7747 1409.34 95.724C1409.37 94.6733 1409.16 93.6287 1408.72 92.6752Z"
          fill="#E5BDBD"
        />
        <path
          d="M1410.72 184.534C1441.12 175.307 1440.16 183.72 1440.16 183.72C1440.16 183.72 1446.4 191.014 1419.07 207.151C1391.74 223.287 1337.7 256.616 1337.7 256.616C1337.7 256.616 1350.62 238.496 1345.85 227.916C1341.09 217.336 1318.65 214.306 1318.65 214.306C1318.65 214.306 1380.31 193.758 1410.72 184.534Z"
          fill="url(#paint2_linear_101_2)"
        />
        <path
          d="M1442.18 111.982C1484.6 105.709 1483.2 120.08 1483.2 120.08C1483.2 120.08 1491.85 134.834 1453.67 154.155C1415.5 173.476 1334.56 212.901 1334.56 212.901C1334.56 212.901 1358.09 186.719 1351.52 166.718C1344.95 146.716 1313.71 134.168 1313.71 134.168C1313.71 134.168 1399.76 118.259 1442.18 111.982Z"
          fill="url(#paint3_linear_101_2)"
        />
        <path
          d="M1483.36 119.714C1483.36 119.714 1490.12 125.78 1472.42 142.892C1454.72 160.003 1334.22 212.132 1334.22 212.132L1358.32 182.379L1418.51 161.185C1418.51 161.185 1454.65 148.054 1468.57 139.777C1474.41 135.548 1480.56 131.283 1482.88 126.71C1483.85 124.506 1484.02 122.03 1483.36 119.714Z"
          fill="#E86C6C"
        />
        <path
          d="M1481.8 116.292C1482.81 117.522 1483.57 118.941 1484.03 120.467C1484.48 121.992 1484.63 123.593 1484.46 125.177C1484.02 129.395 1480.54 134.903 1472.48 142.943C1455.09 160.303 1332.4 213.873 1332.4 213.873L1349.42 201.379C1349.42 201.379 1395.16 181.829 1424.75 167.437C1440.7 159.682 1458.7 151.347 1471.03 141.768C1476.18 137.762 1482.41 131.542 1483.88 125.905C1485.2 120.82 1481.8 116.292 1481.8 116.292Z"
          fill="#E5BDBD"
        />
        <path
          d="M1440.26 183.304C1440.26 183.304 1444.46 186.548 1431.5 199.441C1418.54 212.334 1334.12 258.387 1334.12 258.387L1349.76 237.765L1394.03 216.782C1394.03 216.782 1419.2 204.595 1429.09 197.813C1433.28 194.478 1437.68 191.086 1439.52 187.887C1440.3 186.496 1440.56 184.871 1440.26 183.304Z"
          fill="#E86C6C"
        />
        <path
          d="M1439.13 181.522C1439.76 182.266 1440.21 183.15 1440.43 184.101C1440.65 185.051 1440.65 186.042 1440.41 186.989C1439.88 189.754 1437.22 193.656 1431.36 199.64C1418.69 212.573 1333.25 259.293 1333.25 259.293L1345.39 249.544C1345.39 249.544 1377.21 232.398 1397.91 220.18C1409.06 213.595 1421.62 206.431 1430.44 199.028C1434.13 195.935 1438.67 191.301 1439.97 187.516C1440.31 186.522 1440.41 185.46 1440.27 184.419C1440.12 183.377 1439.73 182.385 1439.13 181.522Z"
          fill="#E5BDBD"
        />
        <path
          d="M1311.51 117.986C1314.05 131.006 1307.22 143.285 1296.27 145.417C1285.32 147.55 1274.39 138.728 1271.85 125.709C1269.32 112.69 1276.14 100.411 1287.09 98.2779C1298.04 96.1453 1308.98 104.97 1311.51 117.986Z"
          fill="url(#paint4_linear_101_2)"
        />
        <path
          d="M1308.12 118.439C1310.43 130.292 1304.22 141.471 1294.25 143.412C1284.28 145.354 1274.33 137.323 1272.02 125.469C1269.71 113.616 1275.92 102.437 1285.89 100.496C1295.86 98.5544 1305.81 106.587 1308.12 118.439Z"
          fill="url(#paint5_linear_101_2)"
        />
        <path
          d="M1349.46 230.461C1351.99 243.478 1345.16 255.752 1334.21 257.878C1323.26 260.005 1312.34 251.181 1309.81 238.163C1307.28 225.146 1314.11 212.873 1325.06 210.746C1336.01 208.619 1346.93 217.447 1349.46 230.461Z"
          fill="url(#paint6_linear_101_2)"
        />
        <path
          d="M1346.07 230.917C1348.37 242.769 1342.16 253.942 1332.19 255.878C1322.22 257.814 1312.28 249.78 1309.97 237.929C1307.67 226.077 1313.89 214.904 1323.85 212.968C1333.82 211.031 1343.77 219.067 1346.07 230.917Z"
          fill="url(#paint7_linear_101_2)"
        />
        <path
          d="M1357.66 164.956C1362.07 187.649 1350.33 209.021 1331.42 212.694C1312.52 216.366 1293.63 200.945 1289.22 178.253C1284.81 155.56 1296.56 134.185 1315.46 130.514C1334.36 126.843 1353.26 142.263 1357.66 164.956Z"
          fill="url(#paint8_linear_101_2)"
        />
        <path
          d="M1352.64 166.181C1356.9 188.094 1346.19 208.607 1328.73 212C1311.27 215.392 1293.66 200.377 1289.4 178.466C1285.15 156.554 1295.85 136.039 1313.31 132.647C1330.77 129.256 1348.38 144.269 1352.64 166.181Z"
          fill="url(#paint9_linear_101_2)"
        />
        <path
          d="M1293.67 119.358C1295.71 125.611 1293.6 131.896 1288.98 133.398C1284.35 134.9 1278.97 131.047 1276.93 124.794C1274.9 118.542 1277.01 112.255 1281.63 110.755C1286.24 109.256 1291.64 113.105 1293.67 119.358Z"
          fill="url(#paint10_linear_101_2)"
        />
        <path
          d="M1276.94 124.792C1278.97 131.044 1283.48 135.182 1287.01 134.034C1290.55 132.887 1291.77 126.888 1289.74 120.636C1287.71 114.384 1283.19 110.246 1279.66 111.394C1276.13 112.541 1274.91 118.54 1276.94 124.792Z"
          fill="url(#paint11_linear_101_2)"
        />
        <path
          d="M1336.91 163.889C1338.55 168.937 1336.85 174.012 1333.12 175.223C1329.39 176.434 1325.04 173.326 1323.4 168.276C1321.76 163.226 1323.45 158.154 1327.18 156.942C1330.92 155.73 1335.27 158.839 1336.91 163.889Z"
          fill="url(#paint12_linear_101_2)"
        />
        <path
          d="M1323.39 168.278C1325.03 173.327 1328.68 176.668 1331.53 175.741C1334.38 174.814 1335.37 169.97 1333.73 164.922C1332.09 159.874 1328.45 156.533 1325.59 157.46C1322.74 158.386 1321.75 163.23 1323.39 168.278Z"
          fill="url(#paint13_linear_101_2)"
        />
        <path
          d="M1316.04 194.302C1317.45 198.654 1321.21 201.336 1324.42 200.291C1327.64 199.247 1329.1 194.871 1327.69 190.519C1326.28 186.166 1322.52 183.484 1319.31 184.529C1316.09 185.574 1314.63 189.949 1316.04 194.302Z"
          fill="url(#paint14_linear_101_2)"
        />
        <path
          d="M1316.04 194.303C1317.45 198.655 1320.59 201.536 1323.05 200.737C1325.51 199.938 1326.36 195.762 1324.95 191.41C1323.53 187.057 1320.39 184.176 1317.93 184.975C1315.47 185.774 1314.63 189.95 1316.04 194.303Z"
          fill="url(#paint15_linear_101_2)"
        />
        <path
          d="M1313.22 151.38C1314.64 155.733 1313.18 160.108 1309.96 161.153C1306.74 162.197 1302.99 159.516 1301.58 155.163C1300.16 150.811 1301.63 146.434 1304.84 145.391C1308.05 144.347 1311.81 147.028 1313.22 151.38Z"
          fill="url(#paint16_linear_101_2)"
        />
        <path
          d="M1301.58 155.165C1302.99 159.518 1306.13 162.398 1308.59 161.599C1311.05 160.8 1311.9 156.624 1310.48 152.272C1309.07 147.919 1305.93 145.038 1303.47 145.837C1301.01 146.636 1300.16 150.812 1301.58 155.165Z"
          fill="url(#paint17_linear_101_2)"
        />
        <path
          d="M1318.8 235.844C1320.83 242.096 1326.22 245.948 1330.84 244.447C1335.46 242.946 1337.56 236.661 1335.53 230.409C1333.5 224.157 1328.11 220.305 1323.49 221.806C1318.87 223.307 1316.77 229.592 1318.8 235.844Z"
          fill="url(#paint18_linear_101_2)"
        />
        <path
          d="M1318.8 235.846C1320.83 242.098 1325.34 246.236 1328.88 245.088C1332.41 243.94 1333.63 237.942 1331.6 231.69C1329.57 225.438 1325.06 221.3 1321.52 222.448C1317.99 223.595 1316.77 229.594 1318.8 235.846Z"
          fill="url(#paint19_linear_101_2)"
        />
        <path
          d="M1309.08 153.188C1310.32 158.556 1299.31 165.68 1284.5 169.099C1269.68 172.518 1231.21 177.453 1229.98 172.085C1228.74 166.718 1265.19 153.079 1280.01 149.66C1294.83 146.241 1307.84 147.821 1309.08 153.188Z"
          fill="url(#paint20_linear_101_2)"
        />
        <path
          d="M1323.2 194.169C1324.44 199.536 1313.43 206.66 1298.61 210.079C1283.79 213.498 1245.33 218.433 1244.09 213.066C1242.85 207.698 1279.31 194.059 1294.13 190.64C1308.94 187.221 1321.96 188.801 1323.2 194.169Z"
          fill="url(#paint21_linear_101_2)"
        />
        <path
          d="M1330.81 166.449C1333.26 175.012 1307.77 189.831 1273.87 199.548C1239.97 209.265 1152.23 227.954 1149.77 219.391C1147.32 210.828 1231.08 178.256 1264.98 168.538C1298.89 158.821 1328.36 157.886 1330.81 166.449Z"
          fill="url(#paint22_linear_101_2)"
        />
        <path
          d="M1283.89 124.009C1285.82 131.769 1270.03 142.379 1248.6 147.708C1244.17 148.811 1208.65 145.702 1208.65 145.702C1208.65 145.702 1198.98 159.864 1187.88 163.091C1176.78 166.317 1164.24 158.591 1164.24 158.591C1164.24 158.591 1154.81 173.82 1147.06 174.528C1139.32 175.237 1133.24 161.425 1133.24 161.425C1133.24 161.425 1109.21 171.241 1109.27 167.853C1109.32 164.466 1123.5 149.224 1133.45 147.877C1143.4 146.529 1149.08 162.465 1149.08 162.465C1149.08 162.465 1152.12 147.841 1157.42 144.794C1162.72 141.747 1170.28 150.29 1170.28 150.29C1170.28 150.29 1181.85 140.647 1191.34 138.225C1197.07 136.812 1203.12 137.665 1208.24 140.605C1208.24 140.605 1216.82 139.21 1222.83 134.994C1230.54 129.595 1221.09 119.151 1236.35 116.405C1251.62 113.659 1281.97 116.253 1283.89 124.009Z"
          fill="url(#paint23_linear_101_2)"
        />
        <path
          d="M1329.36 233.481C1331.29 241.241 1297.45 242.634 1297.45 242.634C1297.45 242.634 1301.16 255.397 1296.9 257.851C1292.63 260.304 1280.39 252.454 1280.39 252.454C1280.39 252.454 1282.67 272.144 1277.87 272.854C1273.07 273.564 1261.19 255.315 1261.19 255.315C1261.19 255.315 1259.72 274.61 1248.18 276.495C1245.65 276.909 1234.14 273.491 1234.14 273.491C1234.14 273.491 1235.23 290.882 1226.69 295.711C1222.85 297.878 1206.82 298.355 1191.75 295.834C1173.7 292.814 1156.4 286.18 1155.9 283.578C1155.43 281.202 1178.38 289.556 1195.62 288.443C1205.77 287.646 1215.39 283.564 1223.01 276.816C1223.01 276.816 1223.02 258.724 1228.83 253.113C1234.64 247.502 1246.25 254.365 1246.25 254.365C1246.25 254.365 1247.58 236.851 1253.45 234.962C1259.33 233.074 1269.76 246.826 1269.76 246.826C1269.76 246.826 1274.32 226.108 1279.72 222.822C1285.11 219.535 1291.34 233.685 1291.34 233.685C1291.34 233.685 1327.43 225.721 1329.36 233.481Z"
          fill="url(#paint24_linear_101_2)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_101_2"
          x1="396.234"
          y1="381.781"
          x2="470.728"
          y2="1127.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D04040" />
          <stop offset="1" stopColor="#682020" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_101_2"
          x1="195.699"
          y1="366.347"
          x2="1172.2"
          y2="307.156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_101_2"
          x1="142.33"
          y1="283.66"
          x2="1065.76"
          y2="281.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_101_2"
          x1="194.081"
          y1="249.483"
          x2="1150.64"
          y2="315.737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4E4E" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_101_2"
          x1="1330.99"
          y1="412.651"
          x2="-353.955"
          y2="196.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F5F5F" />
          <stop offset="1" stopColor="#483030" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_101_2"
          x1="768.196"
          y1="1129.27"
          x2="708.69"
          y2="-231.982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#808080" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_101_2"
          x1="1204.85"
          y1="133.721"
          x2="562.663"
          y2="-405.624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F5F5F" />
          <stop offset="1" stopColor="#483030" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_101_2"
          x1="693.803"
          y1="956.179"
          x2="657.687"
          y2="-208.236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#808080" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_101_2"
          x1="1562.25"
          y1="393.909"
          x2="912.906"
          y2="-320.477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F5F5F" />
          <stop offset="1" stopColor="#483030" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_101_2"
          x1="663.076"
          y1="996.971"
          x2="578.835"
          y2="86.3208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#808080" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_101_2"
          x1="674.397"
          y1="357.527"
          x2="678.155"
          y2="-192.646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_101_2"
          x1="821.564"
          y1="802.933"
          x2="734.937"
          y2="-1156.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#575757" />
          <stop offset="1" stopColor="#111111" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_101_2"
          x1="784.368"
          y1="482.108"
          x2="753.501"
          y2="-325.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_101_2"
          x1="805.392"
          y1="773.166"
          x2="749.612"
          y2="-332.548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#575757" />
          <stop offset="1" stopColor="#111111" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_101_2"
          x1="760.11"
          y1="397.216"
          x2="733.734"
          y2="-152.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_101_2"
          x1="844.205"
          y1="840.417"
          x2="768.036"
          y2="-557.879"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#575757" />
          <stop offset="1" stopColor="#111111" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_101_2"
          x1="739.086"
          y1="364.142"
          x2="714.228"
          y2="-202.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_101_2"
          x1="756.875"
          y1="960.589"
          x2="773.412"
          y2="-780.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#575757" />
          <stop offset="1" stopColor="#111111" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_101_2"
          x1="671.162"
          y1="406.036"
          x2="682.443"
          y2="-93.6848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5757" />
          <stop offset="1" stopColor="#802C2C" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_101_2"
          x1="701.89"
          y1="776.473"
          x2="695.125"
          y2="-501.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#575757" />
          <stop offset="1" stopColor="#111111" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_101_2"
          x1="676.014"
          y1="0.319827"
          x2="610.416"
          y2="1240.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#5D5DA1" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_101_2"
          x1="676.014"
          y1="0.319827"
          x2="610.416"
          y2="1240.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#5D5DA1" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_101_2"
          x1="676.014"
          y1="0.319827"
          x2="610.416"
          y2="1240.18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#5D5DA1" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_101_2"
          x1="-132.598"
          y1="0.319824"
          x2="-242.672"
          y2="2112.64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#5D5DA1" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_101_2"
          x1="1323.93"
          y1="211.656"
          x2="1138.49"
          y2="472.105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#5D5DA1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
