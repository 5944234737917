import { customAlphabet } from 'nanoid';

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';
const LENGTH = 14;

export enum Prefix {
  USER = 'user', // User
  ADDRRESS = 'addr', // Address
  LINK = 'link', // Link
  RELEASE = 'rls', // Release
  PROJECT = 'proj', // Project
  ROYALTY_SPLIT_AGREEMENT = 'ra', // Agreement
  LICENSING_AGREEMENT = 'la', // Agreement
  BOOKING_AGREEMENT = 'ba', // Agreement
}

/**
 * Description: Create a prefixed ID
 *
 * @param  {Prefix} type
 * @returns number
 */
export const generateId = (type: Prefix) => {
  const nanoid = customAlphabet(ALPHABET, LENGTH);
  return `${type}-${nanoid()}`;
};
