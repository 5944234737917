/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-empty-function */
import { useDisconnect, useEmbeddedWallet } from '@thirdweb-dev/react';
import {
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  signIn,
  signOut,
  signUp,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Loader from '@/components/common';
import { Onboarding } from '@/pages/auth/components';
import { authenticatedPaths } from '@/utils';

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: { email: string; password: string };
  signIn: (p: { username: string; password: string }) => Promise<any>;
  signOut: () => Promise<any>;
  signUp: (p: {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
  }) => Promise<any>;
  confirmAccount: (p: { code: string }) => Promise<any>;
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: '',
    password: '',
  },
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  confirmAccount: async () => {},
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [unverifiedAccount] = useState({
    email: '',
    password: '',
  });

  const router = useRouter();
  const embeddedWallet = useEmbeddedWallet();
  const disconnect = useDisconnect();

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async () => {
    const session = await fetchAuthSession();
    try {
      await fetchUserAttributes().then((info) => {
        setUsername(info.preferred_username || null);
      });
      setUser(session.userSub || null);
    } catch {
      setUser(null);
      setUsername(null);
      if (authenticatedPaths.includes(router.pathname)) {
        router.push('/auth');
      }
    } finally {
      if (session?.tokens?.idToken) {
        await embeddedWallet.connect({
          strategy: 'jwt',
          jwt: session.tokens.idToken.toString(),
          encryptionKey: session.userSub!,
        });
      }
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    fetchAuthUser();

    // listening for auth change events
    const authListener = Hub.listen('auth', async ({ payload: { event } }) => {
      console.log('Auth Status Changed Event: ', event);
      switch (event) {
        case 'signedIn':
          await fetchAuthUser();
          console.log('user signed in.');
          break;
        case 'signedOut':
          setUser(null);
          await disconnect();
          break;
        case 'tokenRefresh':
          await fetchAuthUser();
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          await disconnect();
          console.log('failure while refreshing auth tokens.');
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          console.log(
            'failure while trying to resolve signInWithRedirect API.',
          );
          break;
        case 'customOAuthState':
          console.log('custom state returned from CognitoHosted UI');
          break;
        default:
          await fetchAuthUser();
      }
    });

    // cleanup
    return () => {
      authListener();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * confirm account using code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const confirmAccount = async ({ code }: { code: string }) => {
    await confirmSignUp({
      username: unverifiedAccount?.email,
      confirmationCode: code,
    });
    await signIn({
      username: unverifiedAccount?.email,
      password: unverifiedAccount?.password,
    });
  };

  const changeUsername = (username: string) => {
    setUsername(username);
  };

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    signIn,
    signOut,
    signUp,
    confirmAccount,
  };

  if (isAuthenticating && authenticatedPaths.includes(router.pathname)) {
    return <Loader />;
  }

  if (user && !username) {
    return <Onboarding changeUsername={changeUsername} />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
