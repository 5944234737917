export const Astronaut = () => {
  return (
    <svg
      style={{ maxWidth: '60%' }}
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
    >
      <clipPath id="a">
        <path d="m555.73 633.88s69.93.45 89.58 5.47 71.66 122.73 93.18 134 39.8-7.45 63.51-21.35 45.44-30.72 46.12-37.74-9.39-35.07-18.1-40.73-34-6.06-47.49 2.58c-19.82-54-48-132.75-73.84-143.59s-104.15-16-104.15-16z" />
      </clipPath>
      <path
        d="m289.08 381.9s-34 13.45-39 46.12 72.26 128.37 72.26 128.37l238.26-128.39-75.33-132.2z"
        fill="#d6409f"
      />
      <path
        d="m341.76 412.78s-25.07 25.74-32.76 54.22-8.68 79.34-7.4 98.39c37.4 34.61 185.23 5.61 185.23 5.61l142.46-167.58s-24.15-45.85-36.41-58.42-52-28.2-97.85-9.75c-26.91 38.19-153.27 77.53-153.27 77.53z"
        fill="#6e56cf"
      />
      <path
        d="m330.57 426.09s55.31 17.75 117.57-6.3 73.54-91.79 73.54-91.79l-26.68 7.26-153.24 77.52z"
        fill="#6e56cf"
      />
      <path
        d="m555.73 633.88s69.93.45 89.58 5.47 71.66 122.73 93.18 134 39.8-7.45 63.51-21.35 45.44-30.72 46.12-37.74-9.39-35.07-18.1-40.73-34-6.06-47.49 2.58c-19.82-54-48-132.75-73.84-143.59s-104.15-16-104.15-16z"
        fill="#6e56cf"
      />
      <g clipPath="url(#a)">
        <path
          d="m629.29 582.34s56.94 6.23 72.67 27.42 46.9 150.85 81.79 153.92c.56 42-71.7 57.17-71.7 57.17l-173.21-192.46z"
          fill="#6e56cf"
          opacity=".51"
        />
      </g>
      <path
        d="m367.85 623.26s43 90.19 82 114.79 109.66 22.61 129.15 38.72 15.37 93.5 34.85 114 46.89 33.06 72.51 30.75 46.12-14.6 46.38-26.13-.77-21.53-12.81-27.68-29-11.27-29.73-18.44-14.35-116.33-20-146-49.53-70.94-106.08-91.73c-53.55-44.84-135.8-25.63-135.8-25.63z"
        fill="#6e56cf"
      />
      <path
        d="m380.36 647.39s49.35 2.52 93.67-16.95c-8.47-45.1-34.6-58.42-34.6-58.42l-71.58 51.24z"
        fill="#6e56cf"
      />
      <path
        d="m448.65 176.4c-38.65-20.69-119.65-27.67-169.65 34.34s-25.08 126.32-4.07 156.26c22 31.42 97.62 79.44 178.33 34.34s89.17-174.72-4.61-224.94z"
        fill="#6e56cf"
      />
      <path
        d="m442 214.32c34.33 8.2 56.63 56.37 45.1 102.49s-51.25 77.13-95.83 77.38-88.91-30.19-88.67-87.11c.24-54.7 64.85-110.56 139.4-92.76z"
        fill="#161616"
      />
      <path
        d="m343.09 574.58c44.73-20.76 11.27-68.16 55.86-78.94 58.79 8.36 75.05 5.36 75.05 5.36s-112.47 98.78-130.91 73.58z"
        fill="#6e56cf"
      />
      <path
        d="m380.36 447.8 74.44 191.55 105.31-2.51 96.34-60.98 28.7-87.12-69.69-209.08-33.83 97.88-87.63 69.69z"
        fill="#c7c7c7"
      />
      <path
        d="m343.09 574.58c24.68-2 37.25-18.07 44.73-25.2 3.81-3.64 3.05-6.83 1.13-17.33s4.74-21.27 13.84-25.88c5 13.45 11.14 16 15.88 15.37s9.61-28.95 25.11-28.7 27.93 20.76 30 31-7.94 39.45-20.5 59.95-30.28 45.88-70.17 53.82-62.57-12.43-75.64-38.18c-4.41-8.69-6-20.7-5.86-34.08 0 0 8.93 11.79 41.48 9.23z"
        fill="#6e56cf"
      />
      <path
        d="m639.46 351.67s4.75 15.45 1.55 21.47-7.56 11-10.76 18.71 1.54 36.77 15.63 41.76 25.75-8.07 29.47-15.63 1.66-27.41-3.85-41.25-15.87-26.14-32.04-25.06z"
        fill="#6e56cf"
      />
      <path
        d="m655.66 159.71 3.16 6.4a2.65 2.65 0 0 0 2 1.46l7.07 1a2.67 2.67 0 0 1 1.48 4.56l-5.11 5a2.66 2.66 0 0 0 -.77 2.36l1.2 7a2.67 2.67 0 0 1 -3.87 2.82l-6.33-3.32a2.65 2.65 0 0 0 -2.48 0l-6.33 3.32a2.67 2.67 0 0 1 -3.88-2.82l1.21-7a2.66 2.66 0 0 0 -.77-2.36l-5.11-5a2.67 2.67 0 0 1 1.48-4.56l7.07-1a2.65 2.65 0 0 0 2-1.46l3.16-6.4a2.68 2.68 0 0 1 4.82 0z"
        fill="#fa934e"
      />
      <path
        d="m317.34 837 2.65 5.37a2.2 2.2 0 0 0 1.68 1.22l5.93.86a2.24 2.24 0 0 1 1.24 3.82l-4.29 4.18a2.22 2.22 0 0 0 -.64 2l1 5.9a2.25 2.25 0 0 1 -3.25 2.37l-5.3-2.79a2.25 2.25 0 0 0 -2.08 0l-5.28 2.77a2.24 2.24 0 0 1 -3.25-2.37l1-5.9a2.26 2.26 0 0 0 -.65-2l-4.28-4.18a2.24 2.24 0 0 1 1.24-3.82l5.92-.86a2.23 2.23 0 0 0 1.69-1.22l2.65-5.37a2.24 2.24 0 0 1 4.02.02z"
        fill="#fa934e"
      />
      <ellipse
        cx="408.56"
        cy="256.06"
        fill="#ffffff"
        opacity=".2"
        rx="45.63"
        ry="16.14"
        transform="matrix(.97655971 -.21524668 .21524668 .97655971 -45.54 93.94)"
      />
      <path
        d="m468.08 552.56s-28.31-25.3-33.44-25.3-18.79 20-15.71 25.3 18.9 22.66 36.27 28c5.63-10.74 12.88-28 12.88-28z"
        fill="#6e56cf"
      />
      <path
        d="m494 447.23 66.11 189.61 96.34-60.98-74.82-198.32z"
        fill="#e8e8e8"
        opacity=".5"
      />
      <path
        d="m675.87 739.49a123 123 0 0 1 -33 19.57c-11.21 4.32-15 13.16-11.61 29.38s5.29 41.51 12.29 48.17 31.08 3.12 43.56-12.36c-3.91-30.5-11.24-84.76-11.24-84.76z"
        fill="#6e56cf"
      />
      <path
        d="m741.12 574.12s-14 6.1-12.67 15.83 15.63 56.76 21.27 61 22.33-3.28 22.33-3.28c-17.14-48.56-30.93-73.55-30.93-73.55z"
        fill="#6e56cf"
      />
      <g fill="#0091ff">
        <path d="m150.32 686.66c-11.13-23.08 25.14-47.78 56-46s43.83 30.27 54.76 40.9c22.83 22.18 22.11 43.88 6.3 53.21s-102.33-17.58-117.06-48.11z" />
        <path d="m772.88 329.11c-9-7.88.26-44.92 21.52-53.59s47.66.81 53.3 15.67-56.37 54.06-74.82 37.92z" />
        <path d="m877.06 348.93c-4.61 6.57-7.68 25.28 2.05 28.61s17.09-15.92 13.67-25.94-12.64-7.06-15.72-2.67z" />
        <path
          d="m884.06 197.1c-7.26 3.42-19.23 18.12-12.43 25.85s22.73-5.33 24.74-15.72-7.46-12.41-12.31-10.13z"
          opacity=".44"
        />
        <path
          d="m201.38 783.77c-6.28-5-24.75-9.25-28.69.26s14.82 18 25 15.26 7.87-12.18 3.69-15.52z"
          opacity=".44"
        />
        <path
          d="m255.61 675.23c-11.17 2.1-9.37 12.09-5.79 21.14s16.18 11.63 25.07 3.36c-5.77-13.73-19.28-24.5-19.28-24.5z"
          opacity=".64"
        />
        <path
          d="m191.4 660.16c-8 .89-14.52 12.47-8.88 21.86s16.39 16.23 22.54 13.67 7.18-16.4 2.74-25.28-8.71-11.11-16.4-10.25z"
          opacity=".64"
        />
        <path
          d="m822.74 283.5c-4.39 1.4-8.84 6.15-8.45 11.28s4.87 9.61 9.73 9 9-8.46 8-14.1-5.93-7.24-9.28-6.18z"
          opacity=".64"
        />
        <path
          d="m776.25 293.5s6 6 5 12.81-11.34 6.18-11.34 6.18 1.54-11.61 6.34-18.99z"
          opacity=".64"
        />
      </g>
    </svg>
  );
};
