import { zodResolver } from '@hookform/resolvers/zod';
import { IconAt, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Card, CircularProgress, Input } from '@polyphonic/ui';

import { Logo } from '@/components/svg';
import blacklist from '@/data/blacklist.json';
import { USERNAME_REGEX } from '@/utils';
import { api } from '@/utils/api';

type OnboardingInput = {
  preferred_username: string;
};

const OnboardingSchema = z.object({
  preferred_username: z
    .string()
    .trim()
    .min(5, { message: 'Usernames must be at least 5 characters' })
    .max(30, { message: 'Usernames cannot be longer than 30 characters' })
    .regex(USERNAME_REGEX, 'Only letters, numbers and dashes are allowed')
    .trim()
    .toLowerCase()
    .refine((val) => !blacklist.includes(val), {
      message: 'Username not available',
    }),
});

interface OnboardingProps {
  changeUsername: (newUsername: string) => void;
}

export function Onboarding({ changeUsername }: OnboardingProps) {
  const {
    handleSubmit,
    register,
    trigger,
    getFieldState,
    formState: { errors },
  } = useForm<OnboardingInput>({
    resolver: zodResolver(OnboardingSchema),
    mode: 'onChange',
  });

  const [status, setStatus] = useState<
    Record<string, boolean | string | undefined>
  >({
    error: false,
    message: '',
  });

  const [handle, setHandle] = useState<string>('');

  const { refetch: refetchUsername } = api.user.usernameAvailable.useQuery(
    {
      preferred_username: handle,
    },
    {
      enabled: false,
    },
  );

  const [usernameSubmitting, setUsernameSubmitting] = useState<
    'rest' | 'checking' | 'available' | 'taken'
  >('rest');

  const checkUsername = async (preferred_username: string) => {
    await setHandle(preferred_username.trim().toLowerCase());
    await trigger('preferred_username');
    const { invalid, error, isDirty } = getFieldState('preferred_username');
    if (!invalid && !error && isDirty) {
      try {
        await setUsernameSubmitting('checking');
        await refetchUsername().then((res) => {
          res.data
            ? setUsernameSubmitting('taken')
            : setUsernameSubmitting('available');
        });
      } catch (error) {
        setUsernameSubmitting('rest');
      }
    }
  };

  const chooseUsername = api.user.updateUsername.useMutation();

  const handleSetUsername: SubmitHandler<OnboardingInput> = async ({
    preferred_username,
  }) => {
    try {
      setIsSubmitting(true);
      const result = await chooseUsername.mutateAsync({
        preferred_username: preferred_username.trim().toLowerCase(),
      });
      if (result?.updated) {
        setStatus({ error: false, message: result.message });
        changeUsername(preferred_username.trim().toLowerCase());
      } else {
        setStatus({ error: true, message: result.message });
      }
    } catch (e) {
      setStatus({ error: true, message: 'Something went wrong' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <form
      onSubmit={handleSubmit(handleSetUsername)}
      className="flex align-center items-center justify-center h-screen flex-col"
    >
      <div className="flex align-center justify-center items-center text-foreground bg-background mb-5 rounded-full w-[100px] h-[100px] padding-4 shadow-morphic">
        <Logo height="50px" width="50px" />
      </div>
      <Card className="filter-none transform-none flex w-[95%] sm:w-[400px] content-center items-center p-8 shadow-morphic bg-background">
        <h4>Choose a username</h4>
        <p
          className="mb-10 min-h-10"
          style={{
            color: status.error
              ? 'hsl(var(--theme-danger))'
              : 'hsl(var(--theme-success))',
          }}
        >
          {status.message}
        </p>
        <Input
          label="Handle"
          fullWidth
          startContent={<IconAt />}
          endContent={
            usernameSubmitting === 'checking' ? (
              <CircularProgress
                aria-label="Progress indicator"
                classNames={{
                  svg: 'w-6 h-6',
                }}
              />
            ) : usernameSubmitting === 'available' ? (
              <IconCheck
                style={{
                  color: 'hsl(var(--theme-success))',
                }}
              />
            ) : usernameSubmitting === 'taken' ? (
              <IconX
                style={{
                  color: 'hsl(var(--theme-danger))',
                }}
              />
            ) : null
          }
          {...register('preferred_username', {
            required: true,
            onChange: (e) => checkUsername(e.target.value),
          })}
          className="mb-3"
          id="preferred_username"
          aria-label="Username"
          errorMessage={errors.preferred_username?.message}
          variant="faded"
          labelPlacement="outside"
        />
        <Button
          className="text-center mt-3 w-full rounded-full font-semibold"
          type="submit"
          color="primary"
          isLoading={isSubmitting}
          aria-label="Submit username"
        >
          Submit
        </Button>
      </Card>
    </form>
  );
}
