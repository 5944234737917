export const Bandcamp = () => {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      width="22px"
      height="22px"
    >
      <path
        fill="currentColor"
        d="M 16 3 C 8.832 3 3 8.832 3 16 C 3 23.168 8.832 29 16 29 C 23.168 29 29 23.168 29 16 C 29 8.832 23.168 3 16 3 z M 16 5 C 22.065 5 27 9.935 27 16 C 27 22.065 22.065 27 16 27 C 9.935 27 5 22.065 5 16 C 5 9.935 9.935 5 16 5 z M 13.382812 12 L 9.3828125 20 L 18.617188 20 L 22.617188 12 L 13.382812 12 z M 14.617188 14 L 19.382812 14 L 17.382812 18 L 12.617188 18 L 14.617188 14 z"
      />
    </svg>
  );
};
