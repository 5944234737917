export const toastStyles = {
  style: {
    backgroundColor: 'hsl(var(--theme-background))',
    color: 'hsl(var(--theme-foreground))',
  },
  success: {
    style: {
      border: '1px solid hsl(var(--theme-success))',
    },
    iconTheme: {
      primary: 'hsl(var(--theme-success))',
      secondary: 'hsl(var(--theme-foreground))',
    },
  },
  error: {
    style: {
      border: '1px solid hsl(var(--theme-danger))',
    },
    iconTheme: {
      primary: 'hsl(var(--theme-danger))',
      secondary: 'hsl(var(--theme-foreground))',
    },
  },
};
