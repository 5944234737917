import localFont from 'next/font/local';

export const spaceGrotesk = localFont({
  src: './SpaceGrotesk-VariableFont_wght.ttf',
  display: 'swap',
  variable: '--theme-font-sans',
});

export const spartan = localFont({
  src: './Spartan-VariableFont_wght.ttf',
  display: 'swap',
  variable: '--theme-font-display',
});

export const inconsolata = localFont({
  src: './Inconsolata-VariableFont_wdth,wght.ttf',
  display: 'swap',
  variable: '--theme-font-mono',
});

export const spaceGroteskFontClass = spaceGrotesk.variable;
export const spartanFontClass = spartan.variable;
export const inconsolataFontClass = inconsolata.variable;
