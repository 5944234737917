interface LogoProps {
  width?: string;
  height?: string;
  fill?: string;
}

export const Logo = ({ width, height, fill }: LogoProps) => (
  <svg
    fillRule="evenodd"
    viewBox="0 0 56 64"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
  >
    <g fillRule="nonzero" fill={fill ? fill : 'currentColor'}>
      <path
        d="m953.63 499.889v45.823c0 .247-.088.713.066.922.208.281 1.013.32 1.339.392 1.35.244 2.714.406 4.084.484 1.383.105 2.774.062 4.148-.128 5.563-.649 10.718-3.255 14.54-7.349 2.168-2.355 3.861-5.108 4.985-8.105 2.773-7.551 1.479-16.021-3.421-22.4-1.673-2.112-3.686-3.931-5.957-5.381-4.367-2.761-9.388-4.317-14.551-4.509-1.149-.055-2.3-.031-3.446.072-.591.055-1.194.174-1.787.179z"
        transform="translate(-928.908 -499.609)"
      />
      <path
        d="m567 577.863h20.869v-62.863c-1.954.454-3.841 1.156-5.616 2.09-7.137 3.624-12.389 10.14-14.413 17.885-.671 2.951-.953 5.976-.84 9z"
        transform="translate(-566.976 -514.017)"
      />
    </g>
  </svg>
);
