import { zodResolver } from '@hookform/resolvers/zod';
import { resetPassword } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Input, toast } from '@polyphonic/ui';

import { api } from '@/utils/api';

type ForgotPasswordInput = {
  email: string;
};

const ForgotPasswordSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: 'An email address is required' })
    .email('Please enter a valid email address'),
});

export const ForgotPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ForgotPasswordInput>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const [email, setEmail] = useState('');
  const { refetch } = api.account.forgotPassword.useQuery(
    {
      email,
    },
    {
      enabled: false,
    },
  );

  const handleForgotPassword: SubmitHandler<ForgotPasswordInput> = async ({
    email,
  }) => {
    setIsSubmitting(true);
    setEmail(email);
    await refetch()
      .then((res) => {
        resetPassword({ username: res.data! });
        toast.success('Password reset email sent');
        setIsSubmitting(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsSubmitting(false);
      });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <form onSubmit={handleSubmit(handleForgotPassword)} className="w-full">
        <Input
          size="sm"
          id="email"
          label="Email"
          errorMessage={errors.email?.message}
          className="mb-3"
          type="text"
          {...register('email')}
          width="100%"
          variant="faded"
        />
        <Button
          className="text-center w-full mt-3 rounded-full bg-primary text-white font-semibold"
          type="submit"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </>
  );
};
