import axios from 'axios';

import {coinmarketcapAPIKey} from '@/utils/constants';

export const coinmarketcapAPI = async () => {
    const response = await axios.get('https://pro-api.coinmarketcap.com/v2/tools/price-conversion?amount=10&id=1027', {
        headers: {
        'X-CMC_PRO_API_KEY': coinmarketcapAPIKey,
        },
    });
    return response.data;
};