import React from 'react';

import { CircularProgress } from '@polyphonic/ui';

function Loader() {
  return (
    <div className="flex w-screen h-screen align-center justify-center">
      <CircularProgress strokeWidth={8} aria-label="Progress indicator" />
    </div>
  );
}

export default Loader;
