import { Amplify } from 'aws-amplify';

import { env } from '@/env.mjs';

export const config = {
  Auth: {
    Cognito: {
      identityPoolId: env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID, // (required) - Amazon Cognito Identity Pool ID
      userPoolId: env.NEXT_PUBLIC_AWS_USER_POOLS_ID, // (optional) - Amazon Cognito User Pool ID
      userPoolClientId: env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID, // (optional) - Amazon Cognito Web Client ID (App client secret needs to be disabled)
    },
  },
  Storage: {
    S3: {
      bucket: env.NEXT_PUBLIC_AWS_S3_BUCKET, // (required) -  Amazon S3 bucket name
      region: env.NEXT_PUBLIC_AWS_REGION, // (optional) -  Amazon service region
    },
  },
};

Amplify.configure(config, { ssr: true });
