import { env } from '../env.mjs';

const seo = {
  titleTemplate: '%s | Polyphonic',
  defaultTitle: 'Polyphonic',
  description: 'A platform for the entire music production lifecycle.',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: `${env.NEXT_PUBLIC_URL}`,
    site_name: 'Polyphonic',
    title: 'Polyphonic',
    description: 'A platform for the entire music production lifecycle.',
    images: [
      {
        url: `${env.NEXT_PUBLIC_URL}/seo/og.png?v2`,
        width: 1200,
        height: 630,
        alt: 'OG Image Alt',
      },
    ],
  },
  facebook: {
    appId: '226365695077912',
  },
  twitter: {
    handle: '@polyphonic_io',
    site: '@polyphonic_io',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    // Theme Toggle Icon
    // {
    //     rel: 'icon',
    //     href: `${env.NEXT_PUBLIC_URL}/seo/favicon.svg`,
    // },
    {
      rel: 'apple-touch-icon',
      href: `${env.NEXT_PUBLIC_URL}/seo/apple-touch-icon.png`,
      sizes: '180x180',
    },
    {
      rel: 'icon',
      href: `${env.NEXT_PUBLIC_URL}/seo/favicon-32x32.png`,
      type: 'image/png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: `${env.NEXT_PUBLIC_URL}/seo/favicon-16x16.png`,
      type: 'image/png',
      sizes: '16x16',
    },
    {
      rel: 'manifest',
      href: `${env.NEXT_PUBLIC_URL}/seo/site.webmanifest`,
    },
    {
      rel: 'mask-icon',
      href: `${env.NEXT_PUBLIC_URL}/seo/safari-pinned-tab.svg`,
      color: '#8757ff',
    },
    {
      rel: 'shortcut icon',
      href:
        env.NEXT_PUBLIC_URL === 'http://localhost:3000'
          ? `${env.NEXT_PUBLIC_URL}/seo/favicon-dev.ico?v2`
          : `${env.NEXT_PUBLIC_URL}/seo/favicon.ico`,
    },
  ],
  additionalMetaTags: [
    {
      name: 'apple-mobile-web-app-title',
      content: 'Polyphonic',
    },
    {
      name: 'application-name',
      content: 'Polyphonic',
    },
    {
      name: 'msapplication-TileColor',
      content: '#8757ff',
    },
    {
      name: 'msapplication-config',
      content: `${env.NEXT_PUBLIC_URL}/seo/browserconfig.xml`,
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
  ],
};

export default seo;
